import React from "react";

import MouseVector from "../../vectors/Mouse";
import TickVector from "../../vectors/Tick";

import { useSocket } from "../../contexts/SocketContext";

function Task({ name, reward, link, status, id }) {
    const socket = useSocket();

    function startTask() {
        socket.emit("startTask", { id: id });
        
        window.open(link, '_blank').focus();
    };

    function checkTask() {
        socket.emit("checkTask", { id: id });
    };

    return (
        <div className="task">
            <div className="image-container">
                {TickVector}
            </div>

            <div className="text">
                <h2>{name}</h2>
                <h3>+{reward} MOUSE</h3>
            </div>

            <button
                onClick={ status==="active" ? startTask : status==="check" ? checkTask : () => {} }
                className={ status==="active" ? "start" : status==="check" ? "check" : "done" }
            >
                { status==="active" ? "Start" : status==="check" ? "Check" : MouseVector }
            </button>
        </div>
    );
};

export default Task;