import React, { useEffect, useState } from "react";

import MouseVector from "../vectors/Mouse";

import { usePageContext } from "../contexts/PageContext";
import { useSocket } from "../contexts/SocketContext";

import Balance from "../components/HomePage/Balance";
import Task from "../components/HomePage/Task";
import CheckInTask from "../components/HomePage/CheckInTask";
import InvitesTask from "../components/HomePage/InvitesTask";
import WalletTask from "../components/HomePage/WalletTask";
import TransactionTask from "../components/HomePage/TransactionTask";

function HomePage() {
    const { setPage } = usePageContext();
    const socket = useSocket();

    const [ tasks, setTasks ] = useState([]);
    const [ staticTasksStatuses, setStaticTasksStatuses ] = useState("start");

    const [ friends, setFriends ] = useState([]);

    useEffect(() => {
        setTasks(JSON.parse(localStorage.getItem("tasks")));
        setStaticTasksStatuses(JSON.parse(localStorage.getItem("staticTasksStatuses")));
        setFriends(JSON.parse(localStorage.getItem("friends")));

        socket.on("tasks", (data) => {
            setTasks(data.tasks);
            socket.emit("getPoints");
        });

        socket.on("staticTasksStatuses", (data) => {
            setStaticTasksStatuses(data);
            socket.emit("getPoints");
        });

        socket.on("friends", (data) => {
            setFriends(data.friends);
        });

        window.Telegram.WebApp.headerColor = "#007CFF";
        return () => window.Telegram.WebApp.headerColor = "#000000";
    }, []);

    return (
        <div id="home-page">
            <div className="fade-in-eclipse"></div>
            <div id="squek-container">
                {MouseVector}
                <h1>Your Story</h1>

                <button onClick={() => setPage("onboardingCongrats")}>Squek</button>
            </div>

            <Balance />

            <div id="tasks-container">
                <div className="subcontainer">
                    <h1>Daily Tasks</h1>
                    
                    <CheckInTask
                        name={"Daily Check-In"}
                        reward={100}
                        status={staticTasksStatuses.checkIn}
                    />

                    <WalletTask
                        name={"Connect TON Wallet"}
                        reward={2000}
                        status={staticTasksStatuses.wallet}
                    />

                    <TransactionTask 
                        name={"Make a TON Transaction"}
                        reward={20000}
                        status={staticTasksStatuses.transaction}
                    />
                   
                </div>
                <div className="subcontainer">
                    <h1>Tasks</h1>

                    { tasks.map(task => 
                        <Task
                            key={task._id}
                            id={task._id}
                            name={task.name}
                            reward={task.reward}
                            link={task.link}
                            status={task.status}
                        />
                    ) }

                    <InvitesTask
                        number={1}
                        name={"Invite 1 friend"}
                        reward={100}
                        progress={friends.length}
                        total={1}
                    />
                    <InvitesTask
                        number={2}
                        name={"Invite 5 friends"}
                        reward={500}
                        progress={friends.length}
                        total={5}
                    />
                    <InvitesTask
                        number={3}
                        name={"Invite 10 friends"}
                        reward={1000}
                        progress={friends.length}
                        total={10}
                    />
                </div>
            </div>
        </div>
    );
};

export default HomePage;