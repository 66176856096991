import React, { useEffect, useState } from "react";

import MouseVector from "../../vectors/Mouse";

function Points({ points }) {
    const [ number, setNumber ] = useState(0);

    // Make increasing effect
    useEffect(() => {
        const interval = setInterval(() => {
            setNumber(prev => {
                if(prev>=points) {
                    clearInterval(interval);
                    return points;
                } else {
                    return prev+23;
                }
            });
        }, 1);

        return () => clearInterval(interval);
    }, [points]);

    return (
        <div id="points-container">
            {MouseVector}

            <h2>{number}</h2>
        </div>
    )
};

export default Points;