import React, { useEffect, useState } from "react";

import Years from "../components/CongratsPage/Years";
import Points from "../components/CongratsPage/Points";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function CongratsPage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    // Story bar states
    const [ section, setSection ] = useState("years");
    const [ sectionProgress, setSectionProgress ] = useState(0)
    const [ pause, setPause ] = useState(false);

    // Data states
    const [ years, setYears ] = useState(0);
    const [ points, setPoints ] = useState(0);

    useEffect(() => {
        socket.emit("getUser");

        socket.on("user", (data) => {
            setYears(data.years);
            setPoints(data.user.points);
        });

        document.body.style.backgroundImage = 'url("./images/bubbles-bg.svg")';
    
        // Handlers to stop and release story-like view
        document.body.addEventListener("mousedown", () => setPause(true));
        document.body.addEventListener("mouseup", () => setPause(false));
        document.body.addEventListener("touchstart", () => setPause(true));
        document.body.addEventListener("touchend", () => setPause(false));

        return () => {
            document.body.style.backgroundImage = 'none';
        };
    }, []);

    useEffect(() => {
        // Intreval for story bars
        const interval = setInterval(() => {
            if(pause) return;
            
            if(sectionProgress === 100 && section === "years") {
                setSection("points");

                return setSectionProgress(0);
            } else if(sectionProgress === 100) {
                clearInterval(interval)
            };

            return setSectionProgress(sectionProgress+1);
        }, 50);

        return () => clearInterval(interval);
    }, [section, sectionProgress, pause]);

    return (
        <div
            id="congrats-page"
        >
            <div className="story-bars">
                <div className="story-bar"><div 
                    style={{width: section==="years" ? `${sectionProgress}%` : "100%"}}
                /></div>
                <div className="story-bar"><div
                    style={{width: section==="points" ? `${sectionProgress}%` : "0%"}}
                /></div>
            </div>

            <h1>
                { section==="years" && "Elite member!" }
                { section==="points" && "You are amazing!" }
            </h1>
            <h3>
                { section==="years" && "You’ve joined Telegram" }
                { section==="points" && "Here is your $MOUSE reward!" } 
            </h3>

            { section==="years" && <Years years={years} /> }
            { section==="points" && <Points points={points} /> }

            <h4>You’re in the Top { years > 8 ? 5 : (years < 5 ? 25 : 10)  }% Telegram users 🔥</h4>

            <button
                className="continue-button"
                onClick={
                    section==="years" ? () => { setSection("points"); setSectionProgress(0) } :
                    () => setPage("home")
                }
            >Continue</button>
        </div>
    );
};

export default CongratsPage;