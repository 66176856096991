import React, { useEffect, useState } from "react";

import MouseVector from "../vectors/Mouse";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";
import BitGetXMouseVector from "../vectors/BitGetXMouse";

function LoadingPage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    const [ loaded, setLoaded ] = useState({
        tasks: false,
        staticTasksStatuses: false,
        friends: false,
        pointsData: false,
        leadersData: false
    });

    useEffect(() => {
        // Initial data loading
        socket.emit("getTasks");
        socket.emit("getStaticTasksStatuses");
        socket.emit("getFriends");
        socket.emit("getPoints");
        socket.emit("getLeaders");

        socket.on("tasks", (data) => {
            localStorage.setItem("tasks", JSON.stringify(data.tasks));

            setLoaded(prev => { prev["tasks"] = true; return {...prev} })
        });

        socket.on("staticTasksStatuses", (data) => {
            localStorage.setItem("staticTasksStatuses", JSON.stringify(data));

            setLoaded(prev => { prev["staticTasksStatuses"] = true; return {...prev} });
        });

        socket.on("friends", (data) => {
            localStorage.setItem("friends", JSON.stringify(data.friends));

            setLoaded(prev => { prev["friends"] = true; return {...prev} })
        });

        socket.on("points", (data) => {
            localStorage.setItem("pointsData", JSON.stringify(data));

            setLoaded(prev => { prev["pointsData"] = true; return {...prev} })
        });

        socket.on("leaders", (data) => {
            localStorage.setItem("leadersData", JSON.stringify(data));

            setLoaded(prev => { prev["leadersData"] = true; return {...prev} })
        });
    }, []);

    useEffect(() => {
        if(Object.values(loaded).every(state => state)) {
            setPage("home");
        };
    }, [loaded]);

    return (
        <div id="loading-page">
            <div>
                {MouseVector}
                <h2>Loading...</h2>
            </div>

            {BitGetXMouseVector}
        </div>
    )
};

export default LoadingPage;