import React, { useEffect } from "react";

import MouseVector from "../../vectors/Mouse";
import TickVector from "../../vectors/Tick";

import { useSocket } from "../../contexts/SocketContext";

function CheckInTask({ name, reward, status }) {
    const socket = useSocket();

    function claimTask() {
        socket.emit("claimStaticTask", { type: "checkIn" });
    };

    return (
        <div className="task">
            <div className="image-container">
                {TickVector}
            </div>

            <div className="text">
                <h2>{name}</h2>
                <h3>+{reward} MOUSE</h3>
            </div>

            <button
                onClick={status==="claim" ? claimTask : () => {}}
                className={ status==="active" ? "start" : status==="claim" ? "check" : "done" }
            >
                { status==="active" ? "Start" : status==="claim" ? "Claim" : MouseVector }
            </button>
        </div>
    );
};

export default CheckInTask;